import Box from "components/Box";
import RoundedBox from "components/RoundedBox";
import Button from "components/Button";
import Image from "components/Image";
import Typography from "components/Typography";
import Modal from "components/Modal";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AVATAR, SCIENCE, DATAUSAGE, COMMUNITY } from "routes/types";
import { AnswersContext } from "../providers/answers";
import { AnswerProps, getQuestions } from "services/questions";
import { createStyles, withStyles } from "@material-ui/styles";
import Logo from "components/Logo";
import { store } from "providers/authorization";
import { FirebaseAuthContext } from "providers/firebase";
import { LOGO_WHITE_PATH } from "services/config";

const styles = createStyles((theme: any) => ({
  box: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  left: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    flexBasis: "25%",
    padding: "24px",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    [theme.breakpoints.down("xs")]: {
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
      borderBottomLeftRadius: 0,
      flexDirection: "column",
      padding: "12px",
      overflowY: "initial",
      flexBasis: "auto",
    },
  },
  right: {
    display: "flex",
    flexDirection: "column",
    flexBasis: "75%",
    overflowY: "scroll",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    [theme.breakpoints.down("xs")]: {
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 16,
      borderBottomRightRadius: 16,
      flexBasis: "100%",
    },
  },
  column: {
    flexBasis: "30%",
    marginRight: "3.3%",
    paddingBottom: "42px",
    [theme.breakpoints.down("xs")]: {
      flexBasis: "46%",
      paddingBottom: "16px",
    },
  },
  xs: {
    display: "none",
    flexBasis: "33.3%",
    paddingBottom: "42px",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      flexBasis: "50%",
      paddingBottom: "16px",
    },
  },
  sm: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
      flexBasis: "50%",
    },
  },
  nickname: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingBottom: "24px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "row",
      paddingBottom: 0,
    },
  },
  separator: {
    height: "50%",
    width: "1px",
    [theme.breakpoints.down("sm")]: {
      height: "1px",
      width: "95vw",
    },
  },
  links: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  footer: {
    justifyContent: "center",
    paddingTop: "48px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "32px",
    },
  },
  text: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "8px",
    },
  },
  bluebox: {
    display: "flex",
    flexDirection: "column",
    width: "95vw",
    [theme.breakpoints.down("xs")]: {
      borderRadius: 16,
      width: "95vw",
    },
  },
  warning: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: "rgba(255, 255, 255, 0.3)",
  },
}));

type ClassProps = { classes: Partial<Record<"box" | "left" | "right" | "column" | "xs" | "sm" | "nickname" | "separator" | "footer" | "text" | "bluebox" | "links" | "warning", string>> };

const ProfilePage = withStyles(styles)(({ classes }: ClassProps) => {
  const history = useHistory();
  const { data, submit, getAnswers, setData } = useContext(AnswersContext);
  const [retake, setRetake] = useState(false);
  const [hasLoadedAnswers, setHasLoadedAnswers] = useState(false);
  const { state } = useContext(store);
  const [canRetake, setCanRetake] = useState(true);
  const [showWarning, setShowWarning] = useState(false);
  const [localData, setLocalData] = useState<AnswerProps[]>([]);
  const { isAnonymous } = useContext(FirebaseAuthContext);

  const navigateTo = useCallback(
    (page: string) => {
      history.push(page);
    },
    [history]
  );

  const navigateToCommunityChart = useCallback(async () => {
    if (submit) await submit(state?.tenant?.["id"] || "");

    if (submit && canRetake) {
      if (state?.tenant) {
        setCanRetake(false);
      }
    }

    history.push(COMMUNITY);
  }, [history, submit, localData, data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async () => {
      if (getAnswers) {
        let questions = await getQuestions();
        let answers = await getAnswers();
        setCanRetake(!Object.keys(answers).length);

        if (!data?.length) {
          if (!answers || Object.keys(answers).length === 0) {
            navigateTo(AVATAR);
          } else {
            let answered = 0;
            questions.splice(11, 1, {
              id: "100",
              title: "",
              originalTitle: "",
              subtitle: "",
              subOptions: undefined,
              columns: 0,
              answer: [],
              avatar: undefined,
              edit: false,
              shortLabel: "Socio-economic background",
              group: "",
              hint: "",
              key: "occupation",
              type: "single",
              options: questions[11].options,
              order: 11,
            });
            
            let d = questions.map((q) => {
              if (!answers[q.key]?.answer[0]?.text?.toLowerCase().includes("prefer not to say")) answered++;
              if (q.key === "occupation") {
                if(answers[q.key]) {
                  answers[q.key].answer[0].text = q?.options?.find((o: any) => o.key === answers[q.key]?.answer[0].key)?.shortAnswer;
                }
              }

              if(q.key === "sex-characteristics") {
                answers[q.key].answer[0].text = q?.options?.find((o: any) => o.key === answers[q.key]?.answer[0].key)?.shortAnswer;
                if(answers[q.key].answer[0].text!.length === 0) {
                  q.shortLabel = '';
                }
              }
              return { ...q, answer: answers[q.key]?.answer, avatar: answers[q.key]?.avatar };
            });
            if (answered < 3) setRetake(true);
            setLocalData(d);
          }
        } else {
          //First time experience
          let answered = 0;
          let _d = [...data];
          const shortAnswer = questions.find((q: any) => q.key === "occupation")?.options?.find((o: any) => o.key === _d[10]?.answer?.[0]?.key)?.shortAnswer;

          _d.splice(11, 1, {
            id: "100",
            title: "",
            originalTitle: "",
            subtitle: "",
            subOptions: undefined,
            columns: 0,
            avatar: undefined,
            edit: false,
            shortLabel: "Socio-economic background",
            group: "",
            hint: "",
            key: "occupation",
            type: "single",
            options: _d[11].options,
            answer: [{ ..._d[11].answer[0], text: shortAnswer || "" }],
            order: 11,
          });

          setLocalData(
            _d.map((q) => {
              if (!q?.answer?.[0]?.text?.toLowerCase()?.includes("prefer not to say")) answered++;
              if (q.key === "occupation") {
                const shortAnswerText = q?.options?.find((o: any) => o.key === q?.answer[0].key)?.shortAnswer;
                q.answer[0].text = shortAnswerText || '';
              }
              
              if(q.key === "sex-characteristics") {
                const shortAnswerText = q?.options?.find((o: any) => o.key === q?.answer[0].key)?.shortAnswer;
                q.answer[0].text = shortAnswerText || '';
                console.log(q.answer[0].text, q);

                if(q.answer[0].text!.length === 0) {
                  q.shortLabel = '';
                }
              }

              return { ...q};
            })
          );
          if (answered < 3) setRetake(true);
        }

        setHasLoadedAnswers(true);
      }
    })();
  }, [data, navigateTo, getAnswers, setData]);

  const answersText = (answers: any) => {
    return answers?.map((a: any) => a.text)?.join(", ");
  };

  return (
    <Box alignItems={"center"} flexDirection={"column"} justifyContent={"center"} p={2} pt={5} pb={8} mt={2} width={"100%"} height={"100%"} display={"flex"}>
      {hasLoadedAnswers && (
        <Typography variant={"h3"} paragraph color={"#fff"}>
          {"Your SeeMe profile"}
        </Typography>
      )}
      {hasLoadedAnswers && (
        <RoundedBox className={classes.bluebox} variant={"blue"}>
          <Box className={classes.box}>
            <RoundedBox className={classes.left}>
              {localData?.map((option, i) =>
                i === 0 ? (
                  <Box key={option.key} className={classes.nickname}>
                    <RoundedBox borderRadius={100} variant={"grey"}>
                      <Image width={64} height={64} src={`${process.env.PUBLIC_URL}/avatars/${option.avatar}`} />
                    </RoundedBox>
                    <Typography className={classes.text} color={"#0680C2"} variant={"h3"}>
                      {option?.answer?.[0]?.text || undefined}
                    </Typography>
                  </Box>
                ) : (
                  i > (isAnonymous ? 0 : 1) &&
                  i < 5 && (i < 4 || (i === 4 && answersText(option?.answer) !== 'No' && answersText(option?.answer) !== 'Prefer not to say')) && (
                    <Box key={option.key} mb={2} className={classes.sm}>
                      <Typography variant={"subtitle1"}>{option.shortLabel}</Typography>
                      <Typography color={"#565966"} paragraph variant={"subtitle2"}>
                        {answersText(option?.answer)}
                      </Typography>
                    </Box>
                  )
                )
              )}
            </RoundedBox>
            <RoundedBox variant={"grey"} className={classes.right}>
              <Box padding={2} display={"flex"} flexWrap={"wrap"} pt={3}>
                {localData?.map(
                  (option, i) =>
                    (i > (isAnonymous ? 0 : 1) && i < 4 && (
                      <div key={option.key} className={classes.xs}>
                        <Typography variant={"subtitle1"}>{option.shortLabel}</Typography>
                        <Typography color={"#565966"} variant={"subtitle2"}>
                          {answersText(option?.answer)}
                        </Typography>
                      </div>
                    )) ||
                    (i > (isAnonymous ? 4 : 3) && (
                      <div key={option.key} className={classes.column}>
                        <Typography variant={"subtitle1"}>{option.shortLabel}</Typography>
                        <Typography color={"#565966"} variant={"subtitle2"}>
                          {answersText(option?.answer)}
                        </Typography>
                      </div>
                    ))
                )}
              </Box>
            </RoundedBox>
          </Box>
          <Box className={classes.links} alignItems={"center"} justifyContent={"center"}>
            <Button
              variant={"simple"}
              size={"small"}
              onClick={() => {
                if (canRetake || !isAnonymous) {
                  navigateTo(AVATAR);
                } else {
                  setShowWarning(true);
                }
              }}
            >
              {"Retake the questions"}
            </Button>
            <RoundedBox className={classes.separator}></RoundedBox>
            <Button onClick={() => navigateTo(DATAUSAGE)} variant={"simple"} size={"small"}>
              {"How we use data"}
            </Button>
            <RoundedBox className={classes.separator}></RoundedBox>
            <Button onClick={() => navigateTo(SCIENCE)} variant={"simple"} size={"small"}>
              {"The science behind our approach"}
            </Button>
          </Box>
        </RoundedBox>
      )}
      {!retake && hasLoadedAnswers && (
        <Box className={classes.footer}>
          <Typography variant={"h3"} align={"center"} paragraph color={"#FFF"}>
            {"Happy with how it looks?"}
          </Typography>
          <Typography variant={"caption"} align={"center"} paragraph color={"#fff"}>
            {"You won’t be able to change your answers after submitting"}
          </Typography>
        </Box>
      )}
      {retake && hasLoadedAnswers && (
        <Box className={classes.footer}>
          <Typography variant={"h3"} align={"center"} paragraph color={"#FFF"}>
            {"Tell us more about you"}
          </Typography>
          <Typography variant={"caption"} align={"center"} paragraph color={"#fff"}>
            {"You must answer at least 3 questions to submit your response"}
          </Typography>
        </Box>
      )}
      {!retake && hasLoadedAnswers && (
        <Button onClick={navigateToCommunityChart} size={"large"} variant={"filled"} style={{ margin: "14px auto", padding: "0 40px !important", display: "flex" }}>
          {"Submit"}
        </Button>
      )}
      {retake && hasLoadedAnswers && (
        <Button onClick={() => navigateTo(AVATAR)} size={"large"} variant={"filled"} style={{ margin: "14px auto", padding: "0 40px !important", display: "flex" }}>
          {"Retake questions"}
        </Button>
      )}
      {isAnonymous && (
        <Logo variant={"bottom"}>
          <Image src={`${process.env.PUBLIC_URL}/powered-by-white.svg`} width={200} ratio={234 / 21} />
        </Logo>
      )}
      {showWarning && (
        <div className={classes.warning}>
          <Modal
            variant={"filled"}
            visible={showWarning}
            onClose={() => {
              setShowWarning(false);
            }}
            size='medium'
          >
            <Box height={"100%"} maxHeight={"100vh"} display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
              It is not possible to retake the questions as you have already submitted your answers
            </Box>
          </Modal>
        </div>
      )}
    </Box>
  );
});

export default ProfilePage;
